import React from "react"
import Header from "@components/header"
import Footer from "@components/footer"
import { Helmet } from "react-helmet"

// Import image
import favicon from '@images/favicon.ico';

const CorporateLayout = ({ isHomePage, children }) => {
  // const {
  //   wp: {
  //   },
  // } = useStaticQuery(graphql`
  //   query CorporateLayoutQuery {
  //     wp {
  //       generalSettings {
  //         title
  //         description
  //       }
  //     }
  //   }
  // `)
  return (
    <div className="global-wrapper" data-is-root-path={isHomePage}>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Header/>
        <main>{children}</main>
      <Footer/>
    </div>
  )
}

export default CorporateLayout
