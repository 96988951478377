import React from "react"

export default function Footer() {
  const Fcont1Url = '/business/';
  const Fcont2Url = '/outline/';
  const Fcont3Url = '/recruit/';
  const Fcont4Url = '/sdgs/';
  const Fcont5Url = '/contact/';
  const Fcont6Url = '/privacy-policy/';

  return(
    <>
      <div className="md:mt-12 mt-1 text-[12px] ml-[calc(((100vw-100%)/2)*-1)] mr-[calc(((100vw-100%)/2)*-1)]">
     
        {/* Footer Start */}
        <div className="md:pt-[20px]">
          <div className="md:text-[10px] text-[12px] bg-[#061D47] mt-2 mx-auto p-5 text-white w-full">
            <ul class="md:max-w-[1000px] mt-[15px] my-0 mx-auto px-5 text-center md:flex justify-center">
              <li className="px-2 py-2 hover:text-[#061D47]"><a href={Fcont1Url}>事業内容</a></li>
              <li className="px-2 py-2 hover:text-[#061D47]"><a href={Fcont2Url}>企業情報</a></li>
              <li className="px-2 py-2 hover:text-[#061D47]"><a href={Fcont5Url}>問い合わせ</a></li>
              <li className="px-2 py-2 hover:text-[#061D47]"><a href={Fcont3Url}>採用情報</a></li>
              <li className="px-2 py-2 hover:text-[#061D47]"><a href={Fcont6Url}>プライバシーポリシー</a></li>
              <li className="px-2 py-2 hover:text-[#061D47]"><a href={Fcont4Url}>SDGsへの取組方針</a></li>
            </ul>
          </div>

          <div className="text-[10px] text-center pt-2 pb-8 bg-[#061D47]  text-white">
            <p>&copy; Enzine, Inc. All Rights Reserved</p>
          </div>

        </div>
        {/* Footer End */}
      </div>
    </>
  )
}